import { $post } from '@js/request'
import { UserInfo } from '@components/Schema/basic'

export async function userLoginRequest (username: string, password: string): Promise<{ token: string, userInfo: UserInfo }> {
  const res = await $post('/api/common/user_login', {
    username,
    password
  })
  return res.data
}

export async function mobileLoginRequest (mobile: string, verification: string): Promise<{ token: string, userInfo: UserInfo }> {
  const res = await $post('/api/common/mobile_login', {
    mobile,
    verification
  })
  return res.data
}
