import React, { ReactElement, useRef, useEffect } from 'react'
import { Form, Button, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import style from './Index.module.less'
import Logo from '../Logo/Index'
import UserNameItems from './components/UserNameItems/Index'
import { connect } from 'react-redux'
import { setToken, setUserInfo } from '@redux/actions/basic'
import { BASIC_STATE } from '@redux/reducers/basic'
import { UserInfo } from '@src/components/Schema/basic'
import { awaitWrap } from '@js/tool'
import { userLoginRequest, mobileLoginRequest } from './ajax'

// const { Item } = Form

interface FormData {
  username: string
  password: string
  mobile: string
  code: string
}

// 登录回调
async function submitHandle (d: FormData, isLoginByMobile: boolean, h: History, setToken: (v: string) => void, setUserInfo: (v: UserInfo) => void): Promise<void> {
  const hide = message.loading('提交中', 120)
  const [err, data] = await awaitWrap(isLoginByMobile ? mobileLoginRequest(d.mobile, d.code) : userLoginRequest(d.username, d.password))
  hide()
  if (err === null && data !== null) {
    setToken(data.token)
    setUserInfo(data.userInfo)
    h.push('/admin')
  }
}

interface Props {
  setToken: (v: string) => void
  setUserInfo: (v: UserInfo) => void
  token: string
}

function Main ({ setToken, setUserInfo, token }: Props): ReactElement {
  const h = useHistory()
  const formRef = useRef(null)
  useEffect(() => {
    if (token !== '') {
      h.push('/admin')
    }
  }, [token])
  return (
    <div className={style.container}>
      <div className={style['logo-line']}>
        <Logo />
      </div>
      <Form ref={formRef} onFinish={async d => await submitHandle(d, false, h, setToken, setUserInfo)}>
        <UserNameItems />
        <Button className={style.submit} type='primary' block size='large' htmlType='submit'>登录</Button>
      </Form>
      {/* <div className={style['link-line']}>
        <a className={style.link}>忘记密码</a>
      </div> */}
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    token: state.basic.token
  }
}, dispatch => {
  return {
    setToken: (d: string) => dispatch(setToken(d)),
    setUserInfo: (d: UserInfo) => dispatch(setUserInfo(d))
  }
})(Main)
