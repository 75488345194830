import React, { ReactElement, useState, useEffect } from 'react'
import classNames from 'classnames'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import style from './Index.module.less'
import { AuthoritySchema } from '@src/components/Schema/authority'
import { routeKey } from '@router/routeKey'

function Menu ({ data }: { data: AuthoritySchema }): ReactElement {
  const r = routeKey[data.key]
  const h = useHistory()
  return (
    <li>
      {
        r === undefined
          ? (
          <a onClick={() => {
            if (data.children instanceof Array && data.children.length === 0) {
              h.push('/admin/developing')
            }
          }}>{data.name}</a>
            )
          : (
            <NavLink to={r} activeClassName={style.active}>{data.name}</NavLink>
            )
      }
      <SonMenu data={data.children} />
    </li>
  )
}

function SonMenu ({ data }: { data: AuthoritySchema[] | undefined }): ReactElement {
  if (!(data instanceof Array) || data.length === 0) {
    return <span />
  }
  const list = [...data]
  list.sort((a, b) => a.orderno - b.orderno)
  const h = useHistory()
  return (
    <div className={style.sub}>
      <ul>
        {list.map(li => {
          const r = routeKey[li.key]
          const children = li.children instanceof Array ? li.children : []
          children.sort((a, b) => a.orderno - b.orderno)
          return (
            <li key={li.id}>
              {
                r === undefined
                  ? (
                      <a onClick={() => {
                        if (li.children instanceof Array && li.children.length === 0) {
                          h.push('/admin/developing')
                        }
                      }}>{li.name}</a>
                    )
                  : (
                    <NavLink to={r} activeClassName={style.active}>{li.name}</NavLink>
                    )
              }
              <SonMenu data={children} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

interface Props {
  data: AuthoritySchema[]
}

function Main ({ data = [] }: Props): ReactElement {
  const [path, setPath] = useState('/admin')
  const l = useLocation()
  useEffect(() => {
    setPath(l.pathname)
  }, [l])
  return (
    <div className={style.container}>
      <ul>
        <li className={classNames({ [style.active]: path === '/admin' })}>
          <NavLink to='/admin'>首页</NavLink>
        </li>
        {data.map(li => {
          return <Menu key={li.id} data={li} />
        })}
      </ul>
    </div>
  )
}

export default Main
