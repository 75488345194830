import React, { ReactElement, useState } from 'react'
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Menu, Dropdown, message } from 'antd'
import { connect } from 'react-redux'
import { UserInfo } from '@components/Schema/basic'
import { BASIC_STATE } from '@redux/reducers/basic'
import { setToken, setUserInfo, setMenu } from '@redux/actions/basic'
import style from './Index.module.less'
import { logoutRequest } from './ajax'
import { History } from 'history'
import { useHistory } from 'react-router-dom'
import { awaitWrap } from '@src/assets/js/tool'
import { AuthoritySchema } from '@src/components/Schema/authority'
import ModifyPassword from './ModifyPassword'

async function logout (h: History, setToken: (v: string) => void, setUserInfo: (v: UserInfo) => void, setMenu: (v: AuthoritySchema[]) => void): Promise<void> {
  const hide = message.loading('退出中', 120)
  const [e] = await awaitWrap(logoutRequest())
  hide()
  if (e === null) {
    setToken('')
    setUserInfo({
      id: '',
      mobile: '',
      realname: '',
      structure_key_default: '',
      structure_name_default: '',
      tenant_code: '',
      role_key_default: '',
      ico: '',
      gender: '',
      tenant_type: '',
      desktop: '',
      structure_org_name: '',
      structure_name: '',
      structureid: ''
    })
    h.push('/login')
    setMenu([])
  }
}

interface Props {
  userInfo: UserInfo
  setToken: (v: string) => void
  setUserInfo: (v: UserInfo) => void
  setMenu: (v: AuthoritySchema[]) => void
}

function renderMenu (logoutHandle: () => Promise<void>, setVisible: (v: boolean) => void): ReactElement {
  return (
    <Menu>
    <Menu.Item onClick={() => setVisible(true)}>修改密码</Menu.Item>
    <Menu.Divider />
    <Menu.Item danger icon={<PoweroffOutlined />} onClick={logoutHandle}>退出</Menu.Item>
  </Menu>
  )
}

function Main ({ userInfo, setToken, setUserInfo, setMenu }: Props): ReactElement {
  const [visible, setVisible] = useState(false)
  const ico = userInfo.ico
  const h = useHistory()
  async function logoutHandle (): Promise<void> {
    return await logout(h, setToken, setUserInfo, setMenu)
  }
  return (
    <div className={style.container}>
      <ModifyPassword visible={visible} hide={() => setVisible(false)} logoutHandle={logoutHandle} />
      <Dropdown overlay={renderMenu(logoutHandle, setVisible)} trigger={['click']}>
        {
          typeof ico === 'string'
            ? (
              <img className={style.img} src={ico} />
              )
            : (
              <div className={style['icon-box']}>
                <UserOutlined className={style.icon} />
              </div>
              )
        }
      </Dropdown>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {
    setToken: (v: string) => dispatch(setToken(v)),
    setUserInfo: (v: UserInfo) => dispatch(setUserInfo(v)),
    setMenu: (v: AuthoritySchema[]) => dispatch(setMenu(v))
  }
})(Main)
