import React, { ReactElement } from 'react'
import { Spin } from 'antd'
import style from './Index.module.less'

function Main (): ReactElement {
  return (
    <div className={style.container}>
      <Spin size="large" />
    </div>
  )
}

export default Main
