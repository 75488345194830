import { ReactElement, useRef } from 'react'
import { Modal, Form, Input, FormInstance, message } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { updatePasswordRequest } from './ajax'
import { awaitWrap } from '@js/tool'

const { Item } = Form
const { Password } = Input

async function updateHandle (d: { [k: string]: any }, logoutHandle: () => Promise<void>): Promise<void> {
  delete d.confirm_password
  const hide = message.loading('提交中', 60)
  const [e] = await awaitWrap(updatePasswordRequest(d))
  hide()
  if (e === null) {
    message.success('更新成功,请重新登陆', 2).then(async () => {
      await logoutHandle()
    }, null)
  }
}

interface Props {
  visible: boolean
  hide: () => void
  logoutHandle: () => Promise<void>
}

function Main ({ visible, hide, logoutHandle }: Props): ReactElement {
  const formRef = useRef<FormInstance>(null)
  return (
    <Modal title='修改密码' visible={visible} onCancel={hide} onOk={() => formRef.current?.submit()}>
      <Form ref={formRef} labelCol={{ flex: '100px' }} onFinish={async d => await updateHandle(d, logoutHandle)}>
        <Item label='原密码' name='old_password' rules={[{ required: true, message: '原密码不能为空' }]}>
          <Password
            placeholder="请输入"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            allowClear
          />
        </Item>
        <Item label='新密码' name='new_password' hasFeedback rules={[
          { required: true, message: '新密码不能为空' },
          {
            validator: async (_, v) => {
              if (v === undefined || v === '') {
                return await Promise.resolve()
              }
              if (v.length < 6) {
                return await Promise.reject(new Error('新密码长度必须大于6'))
              }
              return await Promise.resolve()
            }
          }]}>
          <Password
            placeholder="请输入"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            allowClear
          />
        </Item>
        <Item label='确认新密码' name='confirm_password' dependencies={['new_password']} hasFeedback rules={[
          { required: true, message: '确认新密码不能为空' },
          ({ getFieldValue }) => ({
            async validator (_, value) {
              if (value === undefined || value === '' || getFieldValue('new_password') === value) {
                return await Promise.resolve()
              }
              return await Promise.reject(new Error('新密码不一致!'))
            }
          })
        ]}>
          <Password
            placeholder="请输入"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            allowClear
          />
        </Item>
      </Form>
    </Modal>
  )
}

export default Main
