import React, { ReactElement, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { UserInfo } from '@components/Schema/basic'
import { BASIC_STATE } from '@redux/reducers/basic'
import style from './Index.module.less'

interface Props {
  userInfo: UserInfo
}

function Main ({ userInfo }: Props): ReactElement {
  const [list, setList] = useState<ReactElement[]>([])
  useEffect(() => {
    const l = []
    const r: ReactElement[] = []
    if (userInfo.structure_org_name !== '' && typeof userInfo.structure_org_name === 'string') {
      l.push(userInfo.structure_org_name)
    }
    if (userInfo.structure_name !== '' && typeof userInfo.structure_name === 'string') {
      l.push(userInfo.structure_name)
    }
    if (userInfo.realname !== '' && typeof userInfo.realname === 'string') {
      l.push(userInfo.realname)
    }
    l.forEach((li, i) => {
      r.push(<span className={style.text} key={i}>{li}</span>)
      if (i !== l.length - 1) {
        r.push(<span className={style.split} key={`s-${i}`} />)
      }
    })
    setList(r)
  }, [userInfo])
  return (
    <div className={style.container}>
      {list}
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
