import { History } from 'history'
import React, { ReactElement } from 'react'
import Router from './router'

interface Props {
  history: History
}

function Main ({ history }: Props): ReactElement {
  return (
    <div>
      <Router history={history} />
    </div>
  )
}

export default Main
