import React, { ReactElement } from 'react'
import logo from '@imgs/login-logo.png'
import style from './Index.module.less'

function Main (): ReactElement {
  return (
    <div className={style.logo}>
      <img src={logo} alt="" />
    </div>
  )
}

export default Main
