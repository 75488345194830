import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'
import { UserInfo } from '@components/Schema/basic'
import { BASIC_STATE } from '@redux/reducers/basic'
import style from './Index.module.less'
import logo from '@imgs/login-logo.png'
import Menu from './components/Menu/Index'
import HeaderIconMenu from './components/HeaderIconMenu/Index'
import PersonInfo from './components/PersonInfo/Index'
import { awaitWrap } from '@src/assets/js/tool'
import { getMenuRequest } from './ajax'
import { setMenu } from '@src/redux/actions/basic'
import { AuthoritySchema } from '@src/components/Schema/authority'

async function getMenu (code: string, setMenu: (d: AuthoritySchema[]) => void): Promise<void> {
  const [e, d] = await awaitWrap(getMenuRequest(code))
  if (e === null && d !== null) {
    d.sort((a, b) => a.orderno - b.orderno)
    setMenu(d)
  }
}

interface Props {
  signature?: string
  userInfo: UserInfo
  menu: AuthoritySchema[]
  setMenu: (d: AuthoritySchema[]) => void
}

function Main ({ signature, userInfo, menu, setMenu }: Props): ReactElement {
  useEffect(() => {
    getMenu(userInfo.id, setMenu).catch(e => console.error(e))
  }, [userInfo])
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.left}>
          <img src={logo} alt="" />
          <span className={style.title}>大连国际仲裁院</span>
          <Menu data={menu} />
        </div>
        <div className={style.right}>
          <PersonInfo />
          <HeaderIconMenu />
        </div>
      </div>
      {signature !== undefined && signature !== '' && <div className={style.signature}>{signature}</div>}
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo,
    menu: state.basic.menu
  }
}, dispatch => {
  return {
    setMenu: (d: AuthoritySchema[]) => dispatch(setMenu(d))
  }
})(Main)
