const lawRoute = {
  // MenuConfigMgt: '/admin/authority-manage', // 权限管理
  MenuRoleMgt: '/admin/role-manage', // 权限管理
  MenuStructureMgt: '/admin/structure-manage', // 部门管理
  MenuCaseInput: '/admin/case-input-list', // 案件录入
  MenuPerformanceList: '/admin/perfromance-list', // 全委绩效考核
  MenuCaseStat: '/admin/case-statistice', // 案件统计
  MenuArbitratorStat: '/admin/arbitrator-statistice', // 仲裁员案件统计
  MenuSeriesMgt: '/admin/series-list', // 系列案列表
  MenuArbitratorMgt: '/admin/arbitrator-list', // 仲裁员列表
  MenuDateLimitConfig: '/admin/date-limit-config', // 时限配置管理
  MenuTribunalStat: '/admin/tribunal-stat', // 组庭情况统计
  MenuCaseHearingStat: '/admin/case-hearing-stat', // 开庭情况统计
  MenuCaseSuspensionStat: '/admin/case-suspension-stat', // 中止情况统计
  AllCaseManage: '/admin/all-case-manage', // 全委案件
  MyCase: '/admin/my-case', // 我的案件
  CaseCheckManage: '/admin/case-check-mange', // 案件审核列表
  SimpleSearch: '/admin/simple-case-search' // 案件审核列表
}

const companyRoute = {

}

export const routeKey: { [k: string]: string } = {
  ...lawRoute,
  ...companyRoute
}
