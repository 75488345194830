import moment, { Moment } from 'moment'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { History } from 'history'
import qs from 'query-string'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

// async函数错误处理方法
export async function awaitWrap<T, U = any> (promise: Promise<T>): Promise<[U | null, T | null]> {
  return await promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, null]>(err => [err, null])
}

// 将分钟转为中文小时分钟表示
export function formatMinuteToCnStr (v: number): string {
  if (typeof v !== 'number') {
    return ''
  }
  const r: string[] = []
  const c = Math.floor(v / 60)
  const m = v % 60
  if (c > 0) {
    r.push(`${c}小时`)
  }
  if (m > 0) {
    r.push(`${m}分钟`)
  }
  return r.join('')
}

// 格式化时间
export function formatDate (t: number, format: string = 'YYYY-MM-DD HH:mm'): string {
  if (typeof t === 'number') {
    return moment(t * 1000).format(format)
  }
  return ''
}

// 过滤空字符
export function filterEmptyStr (v: string | number, empty: string = '无', fixed: boolean = false): string {
  if (typeof v === 'string' && v.length > 0) {
    return v
  } else if (typeof v === 'number') {
    if (fixed) {
      return v.toFixed(2)
    }
    return `${v}`
  }
  return empty
}

// 格式化金钱
export function formatMoney (v: number): string {
  if (typeof v !== 'number') {
    return '0'
  }
  return v.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 跳转到指定锚点
export function scrollToAnchor (hash: string, history?: History, boundary?: string): void {
  const d = document.querySelector(hash)
  if (d !== null) {
    if (window.location.hash !== hash && history !== undefined) {
      history.push(hash)
    }
    scrollIntoView(d, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'start',
      boundary: typeof boundary === 'string' ? document.querySelector(boundary) : undefined
    }).catch(e => console.error(e))
  }
}

// 设置搜索
export function setSearch (h: History, o: { [k: string]: any }): void {
  const l = window.location
  const search = qs.parse(l.search)
  const r: { [k: string]: any } = { ...search, ...o }
  h.push(`?${qs.stringify(r)}`)
}

// 获取搜索
export function getSearch (): { [k: string]: any } {
  return qs.parse(window.location.search)
}

// 开始时间去除时分秒
export function formatBeginDate (s: string | number | Moment): number {
  let beginStr = '0'
  if (s instanceof moment) {
    beginStr = (s as Moment).format('YYYY-MM-DD')
  } else if (typeof s === 'string' && /^\d+$/.test(s)) {
    beginStr = moment(parseInt(s) * 1000).format('YYYY-MM-DD')
  } else if (typeof s === 'number') {
    beginStr = moment(s * 1000).format('YYYY-MM-DD')
  } else {
    return 0
  }
  const end = Math.floor(moment(`${beginStr} 00:00:00`).valueOf() / 1000)
  return end
}

// 结束时间添加上时分秒
export function formatEndDate (s: string | number | Moment): number {
  let endStr = '0'
  if (s instanceof moment) {
    endStr = (s as Moment).format('YYYY-MM-DD')
  } else if (typeof s === 'string' && /^\d+$/.test(s)) {
    endStr = moment(parseInt(s) * 1000).format('YYYY-MM-DD')
  } else if (typeof s === 'number') {
    endStr = moment(s * 1000).format('YYYY-MM-DD')
  } else {
    return 0
  }
  const end = Math.floor(moment(`${endStr} 23:59:59`).valueOf() / 1000)
  return end
}

// 二次确认
export function confirmAction (content: string, callback: () => void): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content,
    onOk () {
      callback()
    }
  })
}
