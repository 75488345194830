import { $post } from '@js/request'

export async function logoutRequest (): Promise<void> {
  const res = await $post('/api/common/logout')
  return res.data
}

export async function updatePasswordRequest (d: { [k: string]: any }): Promise<void> {
  const res = await $post('/api/common/edit_password', d)
  return res.data
}
