import { lazy } from 'react'
import Developing from '../../common/Developing/Index'
interface Page {
  path: string
  component: React.FC<any>
}

export const RouterConfig: Page[] = [
  {
    path: '/admin',
    component: lazy(async () => await import('../pages/Desktop/Index'))
  },
  {
    path: '/admin/structure-manage',
    component: lazy(async () => await import('../../common/StructureManage/Index'))
  },
  {
    path: '/admin/authority-manage',
    component: lazy(async () => await import('../../common/AuthorityManage/Index'))
  },
  {
    path: '/admin/role-manage',
    component: lazy(async () => await import('../../common/RoleManage/Index'))
  },
  {
    path: '/admin/case-input-list',
    component: lazy(async () => await import('../pages/CaseInputList/Index'))
  },
  {
    path: '/admin/case-input/:id',
    component: lazy(async () => await import('../pages/CaseInput/Index'))
  },
  {
    path: '/admin/case-assess/:id',
    component: lazy(async () => await import('../pages/CaseAssess/Index'))
  },
  {
    path: '/admin/case-statistice',
    component: lazy(async () => await import('../pages/CaseStatistic/Index'))
  },
  {
    path: '/admin/perfromance-list',
    component: lazy(async () => await import('../pages/PerformanceList/Index'))
  },
  {
    path: '/admin/perfromance-case-input/:type/:id',
    component: lazy(async () => await import('../pages/PerformanceCaseInput/Index'))
  },
  {
    path: '/admin/perfromance-case-list/:id',
    component: lazy(async () => await import('../pages/PerformanceCaseList/Index'))
  },
  {
    path: '/admin/work-perfromance/:id',
    component: lazy(async () => await import('../pages/WorkPerformance/Index'))
  },
  {
    path: '/admin/work-total-perfromance/:id',
    component: lazy(async () => await import('../pages/TotalWorkPerformance/Index'))
  },
  {
    path: '/admin/ranking',
    component: lazy(async () => await import('../pages/Ranking/Index'))
  },
  {
    path: '/admin/arbitrator-statistice',
    component: lazy(async () => await import('../pages/ArbitratorStatistic/Index'))
  },
  {
    path: '/admin/series-list',
    component: lazy(async () => await import('../pages/SeriesList/Index'))
  },
  {
    path: '/admin/series-case-list/:id',
    component: lazy(async () => await import('../pages/SeriesCaseList/Index'))
  },
  {
    path: '/admin/series-case-input',
    component: lazy(async () => await import('../pages/SeriesCaseInput/Index'))
  },
  {
    path: '/admin/series-case-input/:id',
    component: lazy(async () => await import('../pages/SeriesCaseInput/Index'))
  },
  {
    path: '/admin/arbitrator-list',
    component: lazy(async () => await import('../pages/ArbitratorList/Index'))
  },
  {
    path: '/admin/case-input-batch',
    component: lazy(async () => await import('../pages/CaseInputBatch/Index'))
  },
  {
    path: '/admin/case-input-batch-case-list/:id',
    component: lazy(async () => await import('../pages/CaseInputBatchCaseList/Index'))
  },
  {
    path: '/admin/date-limit-config',
    component: lazy(async () => await import('../pages/DateLimitConfig/Index'))
  },
  {
    path: '/admin/tribunal-stat',
    component: lazy(async () => await import('../pages/TribunalStat/Index'))
  },
  {
    path: '/admin/case-hearing-stat',
    component: lazy(async () => await import('../pages/CaseHearingStat/Index'))
  },
  {
    path: '/admin/case-suspension-stat',
    component: lazy(async () => await import('../pages/CaseSuspensionStat/Index'))
  },
  {
    path: '/admin/all-case-manage',
    component: lazy(async () => await import('../pages/AllCaseManage/Index'))
  },
  {
    path: '/admin/my-case',
    component: lazy(async () => await import('../pages/MyCase/Index'))
  },
  {
    path: '/admin/case-check-mange',
    component: lazy(async () => await import('../pages/CaseCheckManage/Index'))
  },
  {
    path: '/admin/simple-case-search',
    component: lazy(async () => await import('../pages/SimpleSearch/Index'))
  },
  {
    path: '/admin/all-case-detail/:id',
    component: lazy(async () => await import('../pages/CaseDetailAll/Index'))
  },
  {
    path: '/admin/my-case-detail/:id',
    component: lazy(async () => await import('../pages/CaseDetailMy/Index'))
  },
  {
    path: '/admin/audit-case-detail/:id',
    component: lazy(async () => await import('../pages/CaseDetailAudit/Index'))
  },
  {
    path: '/admin/developing',
    component: Developing
  }
]
