import React, { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { UserInfo } from '@components/Schema/basic'
import { BASIC_STATE } from '@redux/reducers/basic'
import LawFirm from './pageGroup/lawFirm/Index'
import style from './Index.module.less'
import Header from './components/Header/Index'
import Footer from './components/Footer/Index'

interface Props {
  userInfo: UserInfo
}

// 默认页面
// function DefaultPage (props: { history: History }): React.ReactElement {
//   return <span />
// }

function Main ({ userInfo }: Props): ReactElement {
  const h = useHistory()
  useEffect(() => {
    const ori = window.document.documentElement.style.backgroundColor
    window.document.documentElement.style.backgroundColor = '#f0f2f5'
    return () => {
      window.document.documentElement.style.backgroundColor = ori
    }
  }, [])
  const Page = LawFirm
  return (
    <div className={style.container}>
      <Header />
      <div className={style.pages}>
        <Page history={h} />
      </div>
      <Footer />
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
