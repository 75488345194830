import React, { ReactElement } from 'react'
import style from './Index.module.less'

function Main (): ReactElement {
  return (
    <div className={style.container}>
    </div>
  )
}

export default Main
