import { Action } from 'redux'
import BASIC from '../actionTypes/basic'
import { UserInfo } from '@components/Schema/basic'
import { AuthoritySchema } from '@src/components/Schema/authority'

export interface SET_TOKEN_ACTION extends Action {
  type: BASIC
  data: NonNullable<string>
}

export interface SET_USERINFO_ACTION extends Action {
  type: BASIC
  data: NonNullable<UserInfo>
}

export interface SET_SMS_SEND_TIME extends Action {
  type: BASIC
  data: number
}

export interface SET_MENU extends Action {
  type: BASIC
  data: AuthoritySchema[]
}

export type BASIC_ACTION = SET_TOKEN_ACTION | SET_USERINFO_ACTION | SET_SMS_SEND_TIME

export const setToken = (data: NonNullable<string>): SET_TOKEN_ACTION => ({
  type: BASIC.SET_TOKEN,
  data
})

export const setUserInfo = (data: NonNullable<UserInfo>): SET_USERINFO_ACTION => ({
  type: BASIC.SET_USER_INFO,
  data
})

export const setSMSSendTime = (data: number): SET_SMS_SEND_TIME => ({
  type: BASIC.SET_SMS_SEND_TIME,
  data
})

export const setMenu = (data: AuthoritySchema[]): SET_MENU => ({
  type: BASIC.SET_MENU,
  data
})
