import { $get_ } from '@js/request'
import { AuthoritySchema } from '@src/components/Schema/authority'
import { tenantCode } from '@js/config'

export async function getMenuRequest (id: string): Promise<AuthoritySchema[]> {
  const res = await $get_('/api/common/mymenu', {
    accountid: id,
    type: 'menu',
    tenant_code: tenantCode
  })
  return res.data
}
