// import { BASIC_ACTION } from '../actions/basic'
import BASIC from '../actionTypes/basic'
import { UserInfo } from '@components/Schema/basic'
import { AnyAction } from 'redux'
import { AuthoritySchema } from '@src/components/Schema/authority'

export interface BASIC_STATE {
  token: NonNullable<string>
  userInfo: NonNullable<UserInfo>
  menu: AuthoritySchema[]
  SMSSendTime: number
}
const initState = {
  token: '',
  userInfo: {
    id: '',
    mobile: '',
    realname: '',
    structure_key_default: '',
    structure_name_default: '',
    tenant_code: '',
    role_key_default: '',
    ico: '',
    gender: '',
    tenant_type: '',
    desktop: '',
    structure_org_name: '',
    structure_name: '',
    structureid: ''
  },
  menu: [],
  SMSSendTime: 0
}

// 处理并返回 state
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: BASIC_STATE = initState, action: AnyAction): BASIC_STATE => {
  switch (action.type) {
    case BASIC.SET_TOKEN:
      return { ...state, token: action.data }
    case BASIC.SET_USER_INFO:
      return { ...state, userInfo: action.data }
    case BASIC.SET_SMS_SEND_TIME:
      return { ...state, SMSSendTime: action.data }
    case BASIC.SET_MENU:
      return { ...state, menu: action.data }
    default:
      return state
  }
}
