import React, { ReactElement } from 'react'
import { Form, Input } from 'antd'
import style from './Index.module.less'
import userIcon from '@imgs/login-user.png'
import passwordIcon from '@imgs/login-password.png'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const { Item } = Form

function Main (): ReactElement {
  return (
    <div>
      <Item name='username' rules={[{ required: true, message: '账号不能为空' }]}>
        <Input className={style.input} placeholder='请输入账号' size='large' prefix={<img src={userIcon} className={style['user-icon']} />} allowClear />
      </Item>
      <Item name='password' rules={[{ required: true, message: '密码不能为空' }]}>
        <Input.Password className={style.input} placeholder='请输入密码' size='large' prefix={<img src={passwordIcon} className={style['password-icon']} />} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} allowClear />
      </Item>
      {/* <div className={style['check-line']}>
        <Item noStyle name='auto' valuePropName='checked'>
          <Checkbox>自动登录</Checkbox>
        </Item>
        <a className={style.link}>忘记密码</a>
      </div> */}
    </div>
  )
}

export default Main
