import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import bg from '@imgs/404.png'
import { Button, Space } from 'antd'
import style from './Index.module.less'

function Main (): ReactElement {
  const h = useHistory()
  return (
    <div className={style.container}>
      <div className={style.body}>
        <Space direction='vertical' size={24}>
          <img className={style.pic} src={bg} />
          <Button type='primary' onClick={() => h.push('/admin')}>返回首页</Button>
        </Space>
      </div>
    </div>
  )
}

export default Main
