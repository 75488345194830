import React, { ReactElement } from 'react'
import style from './Index.module.less'
import LoginForm from './components/LoginForm/Index'

function Main (): ReactElement {
  return (
    <div className={style.container}>
      <div className={style['left-box']}>
        <div className={style['left-box-bg']} />
      </div>
      <div className={style['right-box']}>
        <LoginForm />
      </div>
    </div>
  )
}

export default Main
