import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Result, Button } from 'antd'

function Main (): ReactElement {
  const h = useHistory()
  return (
    <div>
      <Result
      status="404"
      title="404"
      subTitle="您所访问的页面不存在"
      extra={<Button type="primary" onClick={() => h.goBack()}>返回</Button>}
    />
    </div>
  )
}

export default Main
