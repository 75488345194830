import React, { Suspense } from 'react'
import { History } from 'history'
import {
  Route,
  Router,
  Redirect,
  RouteComponentProps,
  Switch
} from 'react-router-dom'
import { RouterConfig } from './routerConfig'
import PageLoading from '@components/PageLoading/Index'

interface RProps {
  history: History
}

interface Props extends RouteComponentProps<any, any, any> {

}

function R ({ history }: RProps): React.ReactElement {
  return (
    <Router history={history}>
      <Suspense fallback={<PageLoading />}>
        <Switch>
          {RouterConfig.map(({ path, component: Component }) => {
            return (
              <Route key={path} exact path={path}>
                {(props: Props) => {
                  return <Component {...props} />
                }}
              </Route>
            )
          })}
          <Route path='/admin*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default R
